body {
  background-color: var(--color-background-light);
  margin: 0;
  font-family: var(--font-family, sans-serif); /* Fallback to Avenir Next if not set */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Global styles for the header */
.global-header {
  height: 80px;
  width: 100%;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logo {
  position: absolute;
  left: 50%; /* Center the logo */
  transform: translateX(-50%); /* Adjust for the logo's width */
}

.address-container {
  position: absolute;
  left: 0; /* Align the address container to the left */
  display: flex;
  align-items: center;
  padding-left: 100px;
}

.address .text {
  margin-left: 10px;
}

.address {
  font-size: 14px;
  color: var(--color-text-light);
  white-space: nowrap; 
  text-decoration: none;
}

.phone-container {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 100px;
  color: var(--color-text-light);
}

.phone .text {
  margin-left: 10px;
}

.phone .text,
.address .text {
  position: relative; 
  top: -6px; 
}

.phone a{
  font-size: 14px;
  color: var(--color-text-light);
  white-space: nowrap; 
  text-decoration: none;
}

.icon {
  display: inline-block !important;
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(1);
}

.check-icon {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.check-icon path{
  stroke: var(--color-primary);
  stroke-width: 1;
}

.global-header .logo img {
  height: 80px; /* Adjust as needed */
  width: auto;
}

/* Global styles for the footer */
.global-footer {
  height: 40px;
  width: 100%;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-light);
}

.global-footer p {
  margin: 0;
  font-size: 0.8em;
}

.footer-link {
  margin-left: 20px;
  color: #007bff;
  font-size: 0.8em;
  color: var(--color-text-light);
}

.page-container {
  padding: 0 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.page-container > * {
  margin-bottom: 20px; /* Ensure 40px space between each component */
}

.hero-banner {
  width: 100vw !important;
  left: 50%; /* Align with the viewport center */
  right: 50%; /* Ensure it spans the full viewport width */
  margin-left: -50vw; /* Offset by half the viewport width to "escape" the container */
  margin-right: -50vw; /* Offset by half the viewport width to "escape" the container */
  position: relative;
  text-align: center;
  background-color: var(--color-background-light); /* Fallback color */
  margin-bottom: 40px;
}

.hero-banner picture,
.hero-banner img {
  width: 100%;
  height: auto;
  display: block;
}

.hero-banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: block; /* Default to visible */
}

.hero-banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
}

.hero-banner.no-image {
  min-height: 350px; /* Set a minimum height when there is no image */
}

.hero-banner.no-image .overlay {
  display: none; /* Hide the overlay when there's no image */
}

.hero-banner h1 {
  margin-bottom: 20px;
  color: var(--color-primary);
}

.hero-banner p {
  font-size: 1.2em;
  color: var(--color-text-dark);
}

/* When there is an image, override the text color to white */
.hero-banner.has-image h1,
.hero-banner.has-image p {
  color: white;
}


.hero-banner-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.hero-banner button,
.hero-banner .sms-button {
  padding: 10px 40px; /* Add consistent vertical and horizontal padding */
  font-size: 1em;
  border-radius: 50px;
  font-weight: bold;
  display: inline-block;
  min-width: 150px; /* Ensure the minimum width is 150px */
  width: max-content; /* Use the text content's size plus padding */
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box; /* Include padding in the width calculation */
}

/* Primary CTA button styles */
.hero-banner button {
  background-color: var(--color-cta);
  color: var(--color-text-light);
  border: none;
}

/* SMS button styles */
.hero-banner .sms-button {
  background-color: white;
  color: var(--color-cta);
  border: 2px solid var(--color-cta); 
  text-decoration: none; 
}

.hero-banner .sms-button:hover {
  background-color: var(--color-cta);
  color: white; 
}

.column-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.column-section-title {
  font-size: 2em;
  text-align: center;
  color: var(--color-text-primary);
}

.column-section-body {
  font-size: 1.2em;
  text-align: center;
  width: 75%;
  color: var(--color-text-dark);
  margin-bottom: 40px;
}

.columns {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.columns.two-columns {
  width: 80%;
}

.columns.two-columns .column {
  flex: 1; /* Optional: Ensure equal width for both columns */
}

.column {
  flex: 1;
  margin: 0 20px;
  text-align: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stack title and description vertically */
}

.column h3 {
  color: var(--color-primary);
}

.column p {
  font-size: 1.1em;
  color: var(--color-text-dark);
}

.column.with-description {
  padding: 20px;
  max-width: 600px;
}

.column.no-description {
  padding: 0;
  border-bottom: 4px solid var(--color-accent);
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.column.icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 4px solid var(--color-accent);
  max-width: 300px;
  background: none;
  box-shadow: none;
}

.column-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 300px;
}

.column-icon {
/*  max-width: 260px;*/
  height: auto;
  display: block;
  margin: 0 auto; /* Center the image */
}

.column-title-container {
  flex-shrink: 0;
  width: 80%;
}

/* Description container is vertically centered in the remaining space */
.column-description-container {
  flex-grow: 1; /* Makes the description container take up remaining space */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the description vertically */
}

.carousel-wrapper {
  display: flex;
  flex-direction: column; /* Stack title and carousel vertically */
  align-items: center; /* Center the content horizontally */
  width: 100%;
  padding: 20px 0; /* Optional padding for top and bottom spacing */
}

.carousel-title {
  text-align: center;
  margin-bottom: 20px;
  color: var(--color-primary);
}

.carousel {
  display: flex;
  justify-content: center;
  background-color: var(--color-background-light);
  padding-bottom: 80px;
  max-width: 80%;
}

.carousel-slide {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
}

.carousel-slide.has-content {
  flex-direction: row; /* Default row layout when there's content */
}

.carousel-slide.no-content {
  flex-direction: column; /* Stack image in the center for no content */
  justify-content: center;
  align-items: center;
  text-align: center; /* Center the image horizontally */
}

.carousel-slide-image {
  display: flex; /* Use flexbox to align the image */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  flex: 1; /* Image container takes up 50% of the slide */
  max-width: 50%;
  padding-right: 20px;
}

.carousel-slide.no-content .carousel-slide-image {
  max-width: 100%; /* Use full width for centered image */
  padding-right: 0;
}

.carousel-slide.no-content .carousel-slide-image img {
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  min-width: 600px; /* Ensure minimum width */
  max-width: 100%; /* Prevent overflowing the container */
  border-radius: 8px;
  object-fit: contain;
}

.carousel-slide-image img {
  width: auto;
  height: 200px;
  border-radius: 8px;
  object-fit: contain;
}

.carousel-slide-content {
  flex: 1; /* Text takes up 50% of the slide */
  padding-left: 20px;
  text-align: left;
  color: var(--color-primary);
}

.carousel-slide h3 {
  margin-bottom: 10px;
}

.carousel-slide p {
  font-size: 1.1em;
  color: var(--color-text-dark);
  width: 400px;
}

.slick-slider {
  max-width: 100%; /* Ensure the slider takes up available space */
}

.carousel-wrapper .slick-prev:before,
.carousel-wrapper .slick-next:before {
  color: var(--color-secondary) !important;
  font-size: 30px;
}

.form-block {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure both columns take the full height */
  padding: 20px;
  background-color: var(--color-section-background);
  height: 100%; /* Ensure the form block takes the full available height */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.form-block-left-column {
  flex: 1; /* Ensure the left column takes up 50% of the space */
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left; /* Horizontally center the image */
  align-items: left; /* Vertically center the image */
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 40px;
}

.form-header {
  max-width: 400px;
}

.aux-info-container {
  text-align: center;
}

.aux-info-container {
  color: var(--color-text-primary);
}

.aux-title {
  font-size: 20px;
  margin-bottom: 40px;
}

.form-block-left-column img {
  width: 100%; /* Ensure the image scales properly */
  height: auto; /* Maintain aspect ratio */
  display: block;
  border-radius: 8px;
}

.map-embed-container {
  width: 100%; 
  height: 400px;
  display: block;
  border-radius: 8px;
}

.form-callout {
  color: var(--color-text-primary);
  font-size: 1.2em;
  padding-bottom: 20px;
}

.form-callout a{
  color: var(--color-cta);
  font-size: 1em;
  text-decoration: none;
  font-weight: bold;
}

.form-label {
  color: var(--color-text-primary);
  font-size: 1.2em;
  padding-top: 20px;
}

/* Right column for the form */
.form-block-right-column {
  flex: 1; /* Ensure the right column takes up 50% of the space */
  max-width: 50%;
  padding: 40px; /* Add 20px of padding on both sides */
  box-sizing: border-box;
}

.form-block-right-column h2{
  color: var(--color-text-primary);
}

/* New container to restrict the form's width */
.form-container {
  max-width: 300px; /* Restrict the width of the form */
  align-items: flex-start;
}

.form-block-right-column h2 {
  margin-bottom: 30px;
}

.form-block-right-column label {
  margin-bottom: 10px;
}

.form-block-right-column input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

input::placeholder {
  color: var(--color-text-placeholder);
  font-size: 1em;
  opacity: 0.8; /* Slight transparency for a subtle look */
}

.form-block-right-column button {
  padding: 10px 20px;
  background-color: var(--color-cta);
  color: var(--color-text-light);
  border: none;
  font-size: 1em;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
}

.form-block-right-column button:hover {
  background-color: var(--color-secondary);
}

.form-description {
  margin-bottom: 40px;
}

.form-submit {
  margin-top: 20px;
}

.contact-info-container {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.form-phone-link {
  color: var(--color-cta);
  font-weight: bold;
}

.form-contact-text {
  font-size: 1.1em;
  margin-top: 20px;
}

.consent-text {
  font-size: 0.8rem;
  color: var(--color-text-dark);
  margin-top: 20px;
}

.error {
  color: var(--color-error);
  font-size: 0.8em;
  margin-top: -15px;
}

.success-screen {
  text-align: center;
}

.success-screen h2 {
  color: var(--color-primary);
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.success-screen p {
  font-size: 1rem;
  color: var(--color-text-dark);
}

.testimonial-slide {
  display: flex !important;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
}

.testimonial-slide-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
  max-width: 600px;
}

.testimonial-slide-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.single-content {
  text-align: center;
  margin: 2rem 0;
}

.single-content-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}

.single-content-description {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  color: var(--color-text-dark);
  max-width: 1200px;
  line-height: 1.5;
}

.single-content-media {
  max-width: 100%;
  margin: 0 auto;
}

.single-content-image,
.single-content-video {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.single-content-video {
  width: 100%;
  max-width: 800px;
  height: 315px;
  border: none;
}

.row-block {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-block-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--color-primary);
  text-align: center;
}

.row-block-body {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.rows {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}

.row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.row-image {
  flex: 1; /* Left-most third */
  max-width: 40%;
  padding-right: 20px;
  box-sizing: border-box;
}

.row-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.row-content {
  flex: 2; /* Right two-thirds */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.row-content.with-image {
  padding-left: 20px;
}

.row-subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--color-primary);
}

.row-body {
  font-size: 1.1rem;
  color: var(--color-text-dark);
  margin-bottom: 20px;
  line-height: 1.5;
}

.accordion-row {
  border-bottom: 1px solid var(--color-border-light);
  padding: 10px 0;
  width: 100%;
}

.accordion-header {
  cursor: pointer;
  font-weight: bold;
  color: var(--color-primary);
  text-align: left;
  text-align: center;
}

.accordion-content {
  padding: 10px 0;
  max-width: 800px;
}



/* Multi-Select Fieldset Container */
.multi-select-fieldset {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  padding: 0;
}

.multi-select-label {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--color-text-primary);
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1em;
  cursor: pointer;
  position: relative; /* Needed for fine-tuning the text position */
  margin-right: 10px;
}

.checkbox-label span {
  position: relative;  
}

.checkbox-input {
  display: none; /* Hide the default checkbox input */
}

.checkbox-custom {
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-border);
  border-radius: 4px;
  background-color: white;
  display: inline-block;
  position: relative;
}

.checkbox-input:checked + .checkbox-custom {
  background-color: var(--color-cta);
  border-color: var(--color-cta);
}

.checkbox-custom::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: white;
  display: none;
  border-radius: 2px;
}

.checkbox-input:checked + .checkbox-custom::after {
  display: block;
}

.error {
  color: var(--color-error);
  font-size: 0.9em;
  margin-top: 5px;
}

.form-field {
  display: flex;
  flex-direction: column; /* Ensure the label and input stack vertically */
  margin-bottom: 20px; /* Add spacing between fields */
}

.single-select-label {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 8px; /* Add space between the label and the dropdown */
  color: var(--color-text-primary);
}

.form-dropdown {
  font-size: 1em;
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-color: white;
  color: var(--color-text-dark);
  width: 80%; 
  appearance: none; 
  position: relative;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 18px 18px;
}

.form-dropdown:focus {
  outline: none;
  border-color: var(--color-cta); /* Highlight the border on focus */
  box-shadow: 0 0 5px var(--color-cta); /* Optional shadow for focus */
}

.form-dropdown::after {
  content: ''; /* Needed for the pseudo-element to display */
  position: absolute;
  top: 50%;
  right: 20px; /* Adjust distance from the right edge */
  transform: translateY(-50%);
  border-width: 6px 6px 0; /* Wider arrow */
  border-style: solid;
  border-color: var(--color-text-primary) transparent transparent; /* Triangular arrow */
  pointer-events: none; /* Prevent interfering with clicks */
}

/* Screens below 1024px */
@media screen and (max-width: 1024px) {

  .address .text,
  .phone .text {
    display: none; /* Hide text */
  }

  .icon {
    display: inline-block !important;
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1); /* Make the icons white */
  }

  .address-container,
  .phone-container {
    padding-left: 60px;
    padding-right: 60px;
  }

  a {
    box-shadow: none !important;
  }

  .hero-banner {
    height: auto; 
  }

  .hero-banner h1 {
    font-size: 1.6em !important;
  }

  .hero-banner p {
    font-size: 1em !important;
    color: var(--color-text-dark);
  }

  .hero-banner-content {
    width: 100%;
  }

  .columns {
    flex-direction: column; /* Stack columns vertically */
    align-items: center; /* Center columns horizontally */
    gap: 20px;
  }

  .column {
    margin: 20px 0; /* Add vertical margin between columns */
    max-width: 90%; /* Limit width of columns to 90% of the container */
    align-items: center;
  }

  .column-section p{
    width: 100%;
  }

  .column-section-title {
    font-size: 1.6em !important;
  }

  .column-section-body {
    font-size: 1em !important;
  }

  .column.no-description {
    min-width: 300px;
  }

  .column.icon-only {
    min-width: 350px;
    min-height: 200px;
    max-height: 180px;
  }

  .carousel-wrapper {
    margin-bottom: -20px !important;
  }

  .carousel {
    display: flex;
    overflow: hidden;
  }

  .carousel-slide {
    display: flex !important; /* Ensure flexbox is applied */
    flex-direction: column !important;; /* Stack content and image vertically */
    align-items: center;
    text-align: center; /* Center text on smaller screens */
    justify-content: left;
    overflow: hidden;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    display: flex; 
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden !important;
  }

  .carousel-slide-image {
    padding-right: 0; /* Remove padding on the right */
    margin-top: 20px; /* Add some margin to separate the image from the content */
    margin-bottom: 20px;
    width: 100%;
    max-width: 100% !important;
    padding-right: 0px !important;
  }

  .carousel-slide-content {
    padding-left: 0; /* Remove padding on the left */
    margin-bottom: 20px; /* Add some space between the content and the image */

  }

  .carousel-slide-content h3{
    text-align: center;
  }

  .carousel-slide-content p{
    max-width: 100%;
  }

  .carousel-wrapper .slick-prev:before,
  .carousel-wrapper .slick-next:before {
    display: none;
  }

  .form-block {
    flex-direction: column; /* Stack the form block image and form vertically */
    align-items: center; /* Center align the form block components */
  }

  .form-block-left-column {
    min-width: 450px;
  }

  .form-block-right-column {
    padding: 20px 20px; /* Adjust padding for form block */
    min-width: 400px;
  }

  .carousel-slide-content, .carousel-slide-image {
    max-width: 100%; /* Ensure images and content take up full width in mobile view */
    flex: none;
  }

  .carousel-slide {
    flex-direction: column; /* Stack image and text vertically on mobile */
    text-align: center;
  }

  .row-image {
    display: none;
  }

}

/* Media query for screens below 480px */
@media screen and (max-width: 480px) {

  .address-container,
  .phone-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .form-header h2{
    max-width: 300px;
  }

  .form-block-left-column {
    min-width: 300px !important;
  }
  
  .form-block-right-column input {
    max-width: 200px !important;
  }

  .form-block-right-column {
    padding: 10px 10px !important;
  }

  .form-header {
    margin-left: 40px;
  }

  .form-description {
    max-width: 300px !important;
  }

  .consent-text {
    width: 80% !important;
  }

  .global-footer {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .global-footer p {
    margin: 0 0 5px;
    padding-top: 5px;
  }

  .footer-link {
    margin: 5px 0;
    color: var(--color-text-light);
    padding-top: 5px;
  }

  .footer-link:hover {
    text-decoration: underline;
  }

}